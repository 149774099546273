import {CardMedia, Stack, Toolbar} from "@mui/material";
import {DarkModeOutlined, LightModeOutlined, Menu} from "@mui/icons-material";
import {selectUI, UI_ACTION_CREATORS} from "../../redux/features/ui/ui-slice";
import {useDispatch, useSelector} from "react-redux";
import {AnimatePresence, motion} from "framer-motion";
import lightLogo from "../../assets/images/logo/logo-light-small.png";
import darkLogo from "../../assets/images/logo/logo-dark-small.png";
import React from "react";

const MobileHeader = () => {
    const {theme} = useSelector(selectUI);
    const dispatch = useDispatch();
    return (
        <Toolbar>
            <Stack
                spacing={2}
                sx={{width: "100%"}}
                direction="row"
                justifyContent="space-between"
                alignItems="center">
                <Menu
                    color="secondary"
                    onClick={() => dispatch(UI_ACTION_CREATORS.toggleDrawer(true))}
                    sx={{
                        padding: 1,
                        fontSize: 40,
                        cursor: 'pointer',
                        borderTopLeftRadius: 0,
                        borderBottomLeftRadius: 32,
                        borderBottomRightRadius: 32,
                        borderTopRightRadius: 32,
                        backgroundColor: 'light.secondary'
                    }}/>

                <Stack spacing={2} direction="row" justifyContent="flex-start" alignItems="center">
                    <AnimatePresence initial={true} presenceAffectsLayout={true} mode="popLayout">
                        {theme === 'light' && (
                            <Stack
                                whileInView={{x: 0, opacity: 1}}
                                initial={{x: '10vw', opacity: 0}}
                                exit={{x: '-100vw', opacity: 0}}
                                transition={{
                                    delay: 0.1,
                                    duration: 0.3,
                                    type: 'spring',
                                    bounce: 0.9,
                                    stiffness: 150
                                }}
                                whileHover={{
                                    scale: 1.2,
                                    transition: {
                                        duration: 0.5,
                                        repeat: Infinity,
                                        repeatType: "reverse"
                                    }
                                }}
                                component={motion.div}
                                sx={{height: "100%"}}
                                direction="row"
                                justifyContent="center"
                                alignItems="center">
                                <CardMedia
                                    component="img"
                                    src={lightLogo}
                                    sx={{width: "100%", height: 75, objectFit: "contain", maxWidth: "100%"}}
                                />
                            </Stack>
                        )}
                    </AnimatePresence>
                    <AnimatePresence initial={true} presenceAffectsLayout={true} mode="popLayout">
                        {theme === 'dark' && (
                            <Stack
                                whileInView={{x: 0, opacity: 1}}
                                initial={{x: '10vw', opacity: 0}}
                                exit={{x: '-100vw', opacity: 0}}
                                transition={{
                                    delay: 0.1,
                                    duration: 0.3,
                                    type: 'spring',
                                    bounce: 0.9,
                                    stiffness: 150
                                }}
                                whileHover={{
                                    scale: 1.2,
                                    transition: {
                                        duration: 0.5,
                                        repeat: Infinity,
                                        repeatType: "reverse"
                                    }
                                }}
                                component={motion.div}
                                sx={{height: "100%"}}
                                direction="row"
                                justifyContent="center"
                                alignItems="center">
                                <CardMedia
                                    component="img"
                                    src={darkLogo}
                                    sx={{width: "100%", height: 75, objectFit: "contain", maxWidth: "100%"}}
                                />
                            </Stack>
                        )}
                    </AnimatePresence>
                </Stack>

                {theme === 'dark' ? (
                    <LightModeOutlined
                        color="secondary"
                        onClick={() => dispatch(UI_ACTION_CREATORS.toggleTheme())}
                        sx={{
                            padding: 1,
                            fontSize: 40,
                            cursor: 'pointer',
                            borderTopLeftRadius: 0,
                            borderBottomLeftRadius: 32,
                            borderBottomRightRadius: 32,
                            borderTopRightRadius: 32,
                            backgroundColor: 'light.secondary'
                        }}/>
                ) : (
                    <DarkModeOutlined
                        color="secondary"
                        onClick={() => dispatch(UI_ACTION_CREATORS.toggleTheme())}
                        sx={{
                            padding: 1,
                            fontSize: 40,
                            cursor: 'pointer',
                            borderTopLeftRadius: 0,
                            borderBottomLeftRadius: 32,
                            borderBottomRightRadius: 32,
                            borderTopRightRadius: 32,
                            backgroundColor: 'light.secondary'
                        }}
                    />
                )}
            </Stack>
        </Toolbar>
    )
}
export default MobileHeader;
