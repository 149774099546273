import {
    Alert,
    AlertTitle,
    Box,
    Button,
    Card,
    CardContent,
    Grid,
    LinearProgress,
    TextField,
    Typography
} from "@mui/material";
import {useDispatch, useSelector} from "react-redux";
import {MESSAGE_ACTION_CREATORS, selectMessage} from "../../redux/features/message/message-slice";
import {useFormik} from "formik";
import * as yup from "yup";
import "yup-phone";
import {motion} from "framer-motion";

const ContactForm = ({title, caption}) => {

    const {messageLoading, messageError, text} = useSelector(selectMessage);
    const dispatch = useDispatch();
    const formik = useFormik({
        initialValues: {
            firstName: '',
            lastName: '',
            email: '',
            phone: '',
            subject: '',
            message: '',
        },
        validateOnChange: true,
        onSubmit: (values, {resetForm, setSubmitting}) => {
            dispatch(MESSAGE_ACTION_CREATORS.sendMessage({
                values: {
                    ...values,
                    to_name: "SuperCraftGH",
                    from_name: `${values.firstName} ${values.lastName}`,
                    email: values.email,
                    from_email: "info@supercraftgh.com"
                },
                resetForm,
                setSubmitting
            }));
        },
        validationSchema: yup.object().shape({
            firstName: yup.string().required('First name required'),
            lastName: yup.string().required('Last name required'),
            email: yup.string().email('Invalid email').required('email required'),
            phone: yup.string().required('Phone name required'),
            subject: yup.string().required('Subject required'),
            message: yup.string().required('Message required'),
        })
    });


    return (
        <form onSubmit={formik.handleSubmit}>
            <Card
                variant="elevation"
                elevation={0}
                sx={{
                    borderBottomRightRadius: 0,
                    borderTopRightRadius: 32,
                    borderBottomLeftRadius: 32,
                    borderTopLeftRadius: 0,
                }}>
                {messageLoading && <LinearProgress variant="query" color="secondary"/>}
                <CardContent>

                    <Typography variant="h4" align="center" sx={{color: 'text.primary', mb: 2}}>
                        {title}
                    </Typography>
                    <Typography variant="body2" align="center" sx={{color: 'text.secondary', mb: 4}}>
                        {caption}
                    </Typography>

                    {messageError && (
                        <Alert
                            sx={{
                                mb: 4,
                                borderBottomRightRadius: 0,
                                borderTopRightRadius: 32,
                                borderBottomLeftRadius: 32,
                                borderTopLeftRadius: 0,
                            }} variant="standard" severity="error">
                            <AlertTitle>{messageError}</AlertTitle>
                        </Alert>
                    )}

                    {text && (
                        <Alert
                            sx={{
                                mb: 4,
                                borderBottomRightRadius: 0,
                                borderTopRightRadius: 32,
                                borderBottomLeftRadius: 32,
                                borderTopLeftRadius: 0,
                            }} variant="standard" severity="success">
                            <AlertTitle>{text}</AlertTitle>
                        </Alert>
                    )}
                    <Box sx={{mb: 2}}>
                        <Grid container={true} spacing={2}>
                            <Grid item={true} xs={12} md={6}>
                                <TextField
                                    fullWidth={true}
                                    placeholder="First Name"
                                    required={true}
                                    label="First Name"
                                    variant="outlined"
                                    helperText={formik.touched.firstName && formik.errors.firstName}
                                    name="firstName"
                                    error={Boolean(formik.touched.firstName && formik.errors.firstName)}
                                    size="medium"
                                    color="secondary"
                                    sx={{backgroundColor: 'background.light', borderRadius: 1}}
                                    value={formik.values.firstName}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                />
                            </Grid>
                            <Grid item={true} xs={12} md={6}>
                                <TextField
                                    fullWidth={true}
                                    placeholder="Last Name"
                                    required={true}
                                    label="Last Name"
                                    variant="outlined"
                                    helperText={formik.touched.lastName && formik.errors.lastName}
                                    name="lastName"
                                    error={Boolean(formik.touched.lastName && formik.errors.lastName)}
                                    size="medium"
                                    color="secondary"
                                    sx={{backgroundColor: 'background.light', borderRadius: 1}}
                                    value={formik.values.lastName}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                />
                            </Grid>
                        </Grid>
                    </Box>
                    <Box sx={{mb: 2}}>
                        <Grid container={true} spacing={2}>
                            <Grid item={true} xs={12} md={6}>
                                <TextField
                                    fullWidth={true}
                                    placeholder="Email"
                                    required={true}
                                    label="Email"
                                    type="email"
                                    variant="outlined"
                                    helperText={formik.touched.email && formik.errors.email}
                                    name="email"
                                    error={Boolean(formik.touched.email && formik.errors.email)}
                                    size="medium"
                                    color="secondary"
                                    sx={{backgroundColor: 'background.light', borderRadius: 1}}
                                    value={formik.values.email}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                />
                            </Grid>
                            <Grid item={true} xs={12} md={6}>
                                <TextField
                                    fullWidth={true}
                                    placeholder="Phone Number"
                                    required={true}
                                    label="Phone Number"
                                    type="tel"
                                    variant="outlined"
                                    helperText={formik.touched.phone && formik.errors.phone}
                                    name="phone"
                                    error={Boolean(formik.touched.phone && formik.errors.phone)}
                                    size="medium"
                                    color="secondary"
                                    sx={{backgroundColor: 'background.light', borderRadius: 1}}
                                    value={formik.values.phone}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                />
                            </Grid>
                        </Grid>
                    </Box>
                    <Box sx={{mb: 2}}>
                        <TextField
                            fullWidth={true}
                            placeholder="Subject"
                            required={true}
                            label="Subject"
                            type="text"
                            variant="outlined"
                            helperText={formik.touched.subject && formik.errors.subject}
                            name="subject"
                            error={Boolean(formik.touched.subject && formik.errors.subject)}
                            size="medium"
                            color="secondary"
                            sx={{backgroundColor: 'background.light', borderRadius: 1}}
                            value={formik.values.subject}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                        />
                    </Box>
                    <Box sx={{mb: 2}}>
                        <TextField
                            fullWidth={true}
                            placeholder="Message"
                            required={true}
                            label="Message"
                            type="text"
                            variant="outlined"
                            helperText={formik.touched.message && formik.errors.message}
                            name="message"
                            error={Boolean(formik.touched.message && formik.errors.message)}
                            size="medium"
                            color="secondary"
                            sx={{backgroundColor: 'background.light', borderRadius: 1}}
                            value={formik.values.message}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            multiline={true}
                            minRows={5}
                        />
                    </Box>
                    <Grid container={true} justifyContent="center">
                        <Grid item={true} xs={12} md={6} lg={4}>
                            <Button
                                component={motion.div}
                                whileHover={{
                                    scale: 1.1,
                                    transition: {
                                        delay: 0.1,
                                        duration: 0.3,
                                        stiffness: 150,
                                        bounce: 1,
                                        repeat: Infinity,
                                        repeatType: "reverse"
                                    }
                                }}
                                whileTap={{
                                    scale: 0.8,
                                    transition: {
                                        delay: 0.1,
                                        duration: 0.3,
                                        stiffness: 150,
                                        bounce: 1,
                                        repeat: Infinity,
                                        repeatType: "reverse"
                                    }
                                }}
                                onClick={formik.handleSubmit}
                                fullWidth={true}
                                type="submit"
                                color="secondary"
                                disabled={messageLoading}
                                disableElevation={true}
                                variant="text"
                                sx={{
                                    backgroundColor: "light.secondary",
                                    textTransform: 'capitalize',
                                    borderTopLeftRadius: 0,
                                    borderBottomLeftRadius: 32,
                                    borderBottomRightRadius: 32,
                                    borderTopRightRadius: 32
                                }}
                                size="large">
                                {messageLoading ? 'Sending...' : 'Send Message'}
                            </Button>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        </form>
    )
}

export default ContactForm;
