import {Box, SwipeableDrawer} from "@mui/material";
import Footer from "../footer/footer";
import Header from "../header/header";
import {useDispatch, useSelector} from "react-redux";
import {selectUI, UI_ACTION_CREATORS} from "../../redux/features/ui/ui-slice";
import Drawer from "../drawer/drawer";

const Layout = ({children}) => {

    const {drawerOpen} = useSelector(selectUI);
    const dispatch = useDispatch();

    return (
        <Box
            sx={{
                "&::-webkit-scrollbar": {display: 'none'}
        }}>
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    minHeight: "100vh",
                    "&::-webkit-scrollbar": {display: 'none'}
            }}>
                <Box>
                    <Header/>
                </Box>
                <Box sx={{flexGrow: 1, mt: 9, maxWidth: "100vw", overflowX: "hidden"}}>
                    {children}
                </Box>
                <Box>
                    <Footer/>
                </Box>
            </Box>
            <SwipeableDrawer
                open={drawerOpen}
                onOpen={() => dispatch(UI_ACTION_CREATORS.toggleDrawer(true))}
                onClose={() => dispatch(UI_ACTION_CREATORS.toggleDrawer(false))}>
                <Drawer/>
            </SwipeableDrawer>
        </Box>
    )
}

export default Layout;
