import {Box, Container, Grid, Stack, Typography} from "@mui/material";
import SocialLink from "./social-link";
import {Call, Instagram, Mail} from "@mui/icons-material";
import {motion} from "framer-motion";

const container = {
    visible: {
        opacity: 1,
        y: 0,
        transition: {
            staggerChildren: 0.3,
            staggerDirection: -1,
            stiffness: 150,
            bounce: 1,
            when: "beforeChildren",
            duration: 0.3
        }
    },
    hidden: {
        opacity: 0,
        y: '10vw'
    },
    hover: {
        scale: 1.1,
        transition: {
            duration: 0.3,
            repeat: Infinity,
            repeatType: "reverse"
        }
    }
}
const item = {
    visible: {
        opacity: 1,
        y: 0,
        transition: {
            stiffness: 150,
            bounce: 1,
            duration: 0.3
        }
    },
    hidden: {
        opacity: 0,
        y: '10vh'
    },
    hover: {
        scale: 1.1,
        transition: {
            duration: 0.3,
            repeat: Infinity,
            repeatType: "reverse"
        }
    }
}

const Copyright = () => {
    return (
        <Box sx={{paddingBottom: 4}}>
            <Container>
                <Grid container={true} spacing={4} justifyContent="space-between" alignItems="center">
                    <Grid item={true} xs={12} md="auto">
                        <Typography
                            component={motion.p}
                            whileInView={{opacity: 1, x: 0}}
                            viewport={{once: true}}
                            initial={{opacity: 0, x: '-10vw'}}
                            variant="body1" sx={{color: 'text.primary', textAlign: {xs: "center", lg: "left"}}}>
                            Copyright © 2022 Supercraft GH. All Rights Reserved
                        </Typography>
                    </Grid>
                    <Grid item={true} xs={12} md="auto">
                        <Stack
                            component={motion.div}
                            variants={container}
                            whileHover="hover"
                            whileInView="visible"
                            initial="hidden"
                            viewport={{once: true}}
                            direction="row" spacing={2} justifyContent="center" alignItems="center">
                            <Box component={motion.div} variants={item}>
                                <SocialLink
                                    icon={
                                        <Instagram
                                            sx={{
                                                backgroundColor: 'light.secondary',
                                                color: "secondary.main",
                                                fontSize: 32,
                                                borderBottomLeftRadius: 0,
                                                borderBottomRightRadius: 32,
                                                borderTopLeftRadius: 32,
                                                borderTopRightRadius: 32,
                                                padding: 0.5
                                            }}
                                        />}
                                    link="https://instagram.com/supercraftgh"
                                />
                            </Box>
                            <Box component={motion.div} variants={item}>
                                <SocialLink
                                    icon={
                                        <Mail
                                            sx={{
                                                backgroundColor: 'light.secondary',
                                                color: "secondary.main",
                                                fontSize: 32,
                                                borderBottomLeftRadius: 0,
                                                borderBottomRightRadius: 32,
                                                borderTopLeftRadius: 32,
                                                borderTopRightRadius: 32,
                                                padding: 0.5
                                            }}
                                        />}
                                    link="mailto:info@supercraftgh.com"
                                />
                            </Box>
                            <Box component={motion.div} variants={item}>
                                <SocialLink
                                    icon={
                                        <Call
                                            sx={{
                                                backgroundColor: 'light.secondary',
                                                color: "secondary.main",
                                                fontSize: 32,
                                                borderBottomLeftRadius: 0,
                                                borderBottomRightRadius: 32,
                                                borderTopLeftRadius: 32,
                                                borderTopRightRadius: 32,
                                                padding: 0.5
                                            }}
                                        />}
                                    link="tel:+233501597254"
                                />
                            </Box>
                        </Stack>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    )
}
export default Copyright;
