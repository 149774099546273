import {Card, CardContent, CardMedia, Stack, Typography} from "@mui/material";

const Mission = ({mission}) => {
    const {image, text, title} = mission;
    return (
        <Stack
            direction="column"
            spacing={2}
            sx={{height: "100%"}}>
            <Stack
                direction="row"
                spacing={2}
                alignItems="center"
                justifyContent="center">
                <CardMedia
                    component="img"
                    sx={{
                        borderTopLeftRadius: 0,
                        borderBottomLeftRadius: 32,
                        borderBottomRightRadius: 32,
                        borderTopRightRadius: 32,
                        width: 50,
                        height: 50
                    }} src={image}/>
            </Stack>
            <Card
                variant="elevation"
                sx={{
                    borderTopLeftRadius: 0,
                    borderBottomLeftRadius: 32,
                    borderBottomRightRadius: 32,
                    borderTopRightRadius: 32,
                    backgroundColor: "background.paper",
                    height: "100%"
                }} elevation={0}>
                <CardContent>
                    <Stack direction="column" spacing={2} alignItems="center">
                        <Typography
                            variant="h6"
                            sx={{
                                color: "text.primary",
                                borderTopLeftRadius: 0,
                                borderBottomLeftRadius: 32,
                                borderBottomRightRadius: 32,
                                borderTopRightRadius: 32,
                            }}>
                            {title}
                        </Typography>
                        <Typography
                            variant="body2"
                            sx={{
                                color: "text.primary",
                                borderTopLeftRadius: 0,
                                borderBottomLeftRadius: 32,
                                borderBottomRightRadius: 32,
                                borderTopRightRadius: 32,
                            }}>
                            {text}
                        </Typography>
                    </Stack>
                </CardContent>
            </Card>
        </Stack>
    )
}

export default Mission;
