import {Box, Grid, Stack, Typography} from "@mui/material";
import {ChevronRight} from "@mui/icons-material";
import {useDispatch} from "react-redux";
import {UI_ACTION_CREATORS} from "../../redux/features/ui/ui-slice";

const SideBarLinkItem = ({icon, label}) => {

    const dispatch = useDispatch();

    const handleClick = () => {
        dispatch(UI_ACTION_CREATORS.toggleDrawer(false));
    }

    return (
        <Box onClick={handleClick}>
            <Grid container={true} justifyContent="space-between" alignItems="center">
                <Grid item={true} xs={10}>
                    <Stack direction="row" spacing={2} alignItems="center">
                        <Box>
                            {icon}
                        </Box>
                        <Typography sx={{color: 'secondary.main'}} fontWeight="bold" variant="body1">{label}</Typography>
                    </Stack>
                </Grid>
                <Grid item={true} xs={2}>
                    <ChevronRight color="secondary" />
                </Grid>
            </Grid>
        </Box>
    )
}

export default SideBarLinkItem;
