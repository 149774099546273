import {createTheme} from "@mui/material";

const darkTheme = createTheme({
    typography: {
        fontFamily: "EuclidCircularA, TTSquares, EuclidCircularA, EuclidCircularB"
    },
    palette: {
        mode: "dark",
        primary: {
            main: "#212529"
        },
        secondary: {
            main: "#f9a34f"
        },
        text: {
            primary: "#FFFFFF",
            secondary: "#908C8C",
            action: "#f9a34f"
        },
        background: {
            paper: "#1F1F1F",
            default: "#000000",
            alternative: "#0000008C"
        },
        light: {
            primary: "rgba(33,37,41,0.3)",
            secondary: "rgba(249,163,79,0.1)",
            action: "rgba(249,163,79,0.3)"
        }
    },
    shape: {
        borderRadius: 0
    }
});

const lightTheme = createTheme({
    typography: {
        fontFamily: "EuclidCircularA, TTSquares, EuclidCircularA, EuclidCircularB"
    },
    palette: {
        mode: "light",
        primary: {
            main: "#212529"
        },
        secondary: {
            main: "#f9a34f"
        },
        text: {
            secondary: '#828395',
            primary: '#2f2f2f',
            action: "#f9a34f"
        },
        background: {
            default: '#ffffff',
            alternative: '#e5e5e5',
            paper: '#f5f5f5'
        },
        light: {
            primary: "rgba(33,37,41,0.3)",
            secondary: "rgba(249,163,79,0.1)",
            action: "rgba(249,163,79,0.3)"
        }
    },
    shape: {
        borderRadius: 0
    }
});


export const THEMES = {darkTheme, lightTheme};
