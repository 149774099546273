import {Card, CardContent, Stack, Typography} from "@mui/material";
import {MiscellaneousServicesOutlined} from "@mui/icons-material";

const Service = ({service}) => {
    return (
        <Card
            variant="elevation"
            sx={{
                borderTopLeftRadius: 0,
                borderBottomLeftRadius: 32,
                borderBottomRightRadius: 32,
                borderTopRightRadius: 32,
                height: "100%",
                backgroundColor: "background.paper"
            }} elevation={0}>
            <CardContent>
                <Stack direction="row" spacing={2} alignItems="center">
                    <MiscellaneousServicesOutlined
                        color="secondary"
                        sx={{
                            backgroundColor: "light.secondary",
                            borderTopLeftRadius: 0,
                            borderBottomLeftRadius: 32,
                            borderBottomRightRadius: 32,
                            borderTopRightRadius: 32,
                            padding: 0.8,
                            fontSize: 36
                        }}/>
                    <Typography
                        variant="body2"
                        sx={{
                            color: "text.primary",
                            borderTopLeftRadius: 0,
                            borderBottomLeftRadius: 32,
                            borderBottomRightRadius: 32,
                            borderTopRightRadius: 32,
                        }}>
                        {service}
                    </Typography>
                </Stack>
            </CardContent>
        </Card>
    )
}

export default Service;
