import {AppBar, Box} from "@mui/material";
import MobileHeader from "./mobile-header";
import DesktopHeader from "./desktop-header";

const Header = () => {
    return (
        <AppBar
            sx={{
                backgroundColor: "background.paper",
                borderBottomWidth: 1,
                borderBottomStyle: "solid",
                borderBottomColor: "divider"
            }} square={true} elevation={0}>
            <Box sx={{display: {xs: "block", lg: "none"}}}>
                <MobileHeader/>
            </Box>
            <Box sx={{display: {xs: "none", lg: "block"}}}>
                <DesktopHeader/>
            </Box>
        </AppBar>
    )
}

export default Header;
