import './App.css';
import {Route, Routes} from "react-router";
import {CssBaseline, ThemeProvider} from "@mui/material";
import {useSelector} from "react-redux";
import {selectUI} from "./redux/features/ui/ui-slice";
import {THEMES} from "./utils/themes";
import IndexPage from "./pages/index/index-page";
import NotFoundPage from "./pages/404/404-page";

function App() {
    const {theme} = useSelector(selectUI);

    return (
        <ThemeProvider theme={theme === 'dark' ? THEMES.darkTheme : THEMES.lightTheme}>
            <CssBaseline enableColorScheme={true}>
                <Routes>
                    <Route element={<IndexPage/>} path="/" exact={true}/>
                    <Route element={<NotFoundPage/>} path="*" exact={true}/>
                </Routes>
            </CssBaseline>
        </ThemeProvider>
    );
}

export default App;
