import {Box, Button, CardMedia, Grid, Stack, Toolbar} from "@mui/material";
import {Link as ScrollLink} from "react-scroll";
import {useDispatch, useSelector} from "react-redux";
import {selectUI, UI_ACTION_CREATORS} from "../../redux/features/ui/ui-slice";
import {DarkModeOutlined, LightModeOutlined} from "@mui/icons-material";
import {AnimatePresence, motion} from "framer-motion";
import lightSmallLogo from "../../assets/images/logo/logo-light-small.png";
import darkSmallLogo from "../../assets/images/logo/logo-dark-small.png";

const container = {
    hidden: {
        opacity: 0,
        y: '-100vh'
    },
    visible: {
        opacity: 1,
        y: 0,
        transition: {
            bounce: 1,
            staggerChildren: 0.4,
            when: "beforeChildren",
            stiffness: 150
        }
    }

}
const item = {
    hidden: {
        opacity: 0,
        y: '-100vh'
    },
    visible: {
        opacity: 1,
        y: 0,
        transition: {
            bounce: 1,
            stiffness: 150
        }
    },
};

const DesktopHeader = () => {
    const {theme} = useSelector(selectUI);
    const dispatch = useDispatch();

    return (
        <Toolbar>
            <Grid container={true} alignItems="center" justifyContent="space-around">
                <Grid xs="auto" item={true}>
                    <AnimatePresence initial={true} presenceAffectsLayout={true} mode="popLayout">
                        {theme === 'light' && (
                            <Stack
                                whileInView={{x: 0, opacity: 1}}
                                initial={{x: '-10vw', opacity: 0}}
                                exit={{x: '100vw', opacity: 0}}
                                transition={{
                                    delay: 0.2,
                                    duration: 0.5,
                                    type: 'spring',
                                    bounce: 0.9,
                                    stiffness: 1250
                                }}
                                whileHover={{
                                    scale: 1.2,
                                    transition: {
                                        duration: 0.5,
                                        repeat: Infinity,
                                        repeatType: "reverse"
                                    }
                                }}
                                component={motion.div}
                                sx={{height: "100%"}}
                                direction="row"
                                justifyContent="center"
                                alignItems="center">
                                <CardMedia
                                    component="img"
                                    src={lightSmallLogo}
                                    sx={{width: "100%", height: 75, objectFit: "contain", maxWidth: "100%"}}
                                />
                            </Stack>
                        )}
                    </AnimatePresence>
                    <AnimatePresence initial={true} presenceAffectsLayout={true} mode="popLayout">
                        {theme === 'dark' && (
                            <Stack
                                whileInView={{x: 0, opacity: 1}}
                                initial={{x: '-10vw', opacity: 0}}
                                exit={{x: '100vw', opacity: 0}}
                                transition={{
                                    delay: 0.2,
                                    duration: 0.5,
                                    type: 'spring',
                                    bounce: 0.9,
                                    stiffness: 1250
                                }}
                                whileHover={{
                                    scale: 1.2,
                                    transition: {
                                        duration: 0.5,
                                        repeat: Infinity,
                                        repeatType: "reverse"
                                    }
                                }}
                                component={motion.div}
                                sx={{height: "100%"}}
                                direction="row"
                                justifyContent="center"
                                alignItems="center">
                                <CardMedia
                                    component="img"
                                    src={darkSmallLogo}
                                    sx={{width: "100%", height: 75, objectFit: "contain", maxWidth: "100%"}}
                                />
                            </Stack>
                        )}
                    </AnimatePresence>
                </Grid>
                <Grid xs={8} item={true}>
                    <Stack
                        component={motion.div}
                        variants={container}
                        animate="visible"
                        initial="hidden"
                        direction="row"
                        alignItems="center"
                        justifyContent="center"
                        spacing={2}>
                        <Box component={motion.div} variants={item}>
                            <ScrollLink
                                activeStyle={{
                                    color: '#f9a34f',
                                    backgroundColor: "rgba(33,37,41,0.3)",
                                    borderTopLeftRadius: 0,
                                    borderBottomLeftRadius: 32,
                                    borderBottomRightRadius: 32,
                                    borderTopRightRadius: 32,
                            }} offset={-70} spy={true} smooth={true} duration={1000} delay={250} to="home">
                                <Button
                                    size="large"
                                    variant="text"
                                    sx={{
                                        textTransform: 'capitalize',
                                        color: 'text.secondary',
                                        borderTopLeftRadius: 0,
                                        borderBottomLeftRadius: 32,
                                        borderBottomRightRadius: 32,
                                        borderTopRightRadius: 32,
                                        '&:hover': {
                                            color: 'secondary.main',
                                            transition: '300ms ease-in-out'
                                        }
                                    }}>
                                    Home
                                </Button>
                            </ScrollLink>
                        </Box>
                        <Box component={motion.div} variants={item}>
                            <ScrollLink
                                activeStyle={{
                                    color: '#f9a34f',
                                    backgroundColor: "rgba(33,37,41,0.3)",
                                    borderTopLeftRadius: 0,
                                    borderBottomLeftRadius: 32,
                                    borderBottomRightRadius: 32,
                                    borderTopRightRadius: 32,
                                }}
                                offset={-70} spy={true} smooth={true} duration={1000} delay={250} to="services">
                                <Button
                                    size="large"
                                    variant="text"
                                    sx={{
                                        textTransform: 'capitalize',
                                        color: 'text.secondary',
                                        borderTopLeftRadius: 0,
                                        borderBottomLeftRadius: 32,
                                        borderBottomRightRadius: 32,
                                        borderTopRightRadius: 32,
                                        '&:hover': {
                                            color: 'secondary.main',
                                            transition: '300ms ease-in-out'
                                        }
                                    }}>
                                    Services
                                </Button>
                            </ScrollLink>
                        </Box>
                        <Box component={motion.div} variants={item}>
                            <ScrollLink
                                activeStyle={{
                                    color: '#f9a34f',
                                    backgroundColor: "rgba(33,37,41,0.3)",
                                    borderTopLeftRadius: 0,
                                    borderBottomLeftRadius: 32,
                                    borderBottomRightRadius: 32,
                                    borderTopRightRadius: 32,
                                }}
                                offset={-70} spy={true} smooth={true} duration={1000} delay={250} to="about">
                                <Button
                                    size="large"
                                    variant="text"
                                    sx={{
                                        textTransform: 'capitalize',
                                        color: 'text.secondary',
                                        borderTopLeftRadius: 0,
                                        borderBottomLeftRadius: 32,
                                        borderBottomRightRadius: 32,
                                        borderTopRightRadius: 32,
                                        '&:hover': {
                                            color: 'secondary.main',
                                            transition: '300ms ease-in-out'
                                        }
                                    }}>
                                    About
                                </Button>
                            </ScrollLink>
                        </Box>
                        <Box component={motion.div} variants={item}>
                            <ScrollLink
                                activeStyle={{
                                    color: '#f9a34f',
                                    backgroundColor: "rgba(33,37,41,0.3)",
                                    borderTopLeftRadius: 0,
                                    borderBottomLeftRadius: 32,
                                    borderBottomRightRadius: 32,
                                    borderTopRightRadius: 32,
                                }}
                                offset={-70} spy={true} smooth={true} duration={1000} delay={200} to="contact">
                                <Button
                                    size="large"
                                    variant="text"
                                    sx={{
                                        textTransform: 'capitalize',
                                        color: 'text.secondary',
                                        borderTopLeftRadius: 0,
                                        borderBottomLeftRadius: 32,
                                        borderBottomRightRadius: 32,
                                        borderTopRightRadius: 32,
                                        '&:hover': {
                                            color: 'secondary.main',
                                            transition: '300ms ease-in-out'
                                        }
                                    }}>
                                    Contact
                                </Button>
                            </ScrollLink>
                        </Box>
                    </Stack>
                </Grid>
                <Grid xs="auto" item={true}>
                    <Box
                        animate={{x: 0, opacity: 1}}
                        initial={{x: '100vw', opacity: 0}}
                        transition={{
                            delay: 0.5,
                            duration: 1,
                            type: 'spring',
                            bounce: 1,
                            stiffness: 250
                        }}
                        whileHover={{
                            rotateZ: 360,
                            scale: 1.2,
                            transition: {
                                delay: 0.3,
                                duration: 1
                            }
                        }}
                        component={motion.div}>
                        {theme === 'dark' ? (
                            <LightModeOutlined
                                color="secondary"
                                onClick={() => dispatch(UI_ACTION_CREATORS.toggleTheme())}
                                sx={{
                                    padding: 0.6,
                                    fontSize: 36,
                                    cursor: 'pointer',
                                    borderTopLeftRadius: 0,
                                    borderBottomLeftRadius: 32,
                                    borderBottomRightRadius: 32,
                                    borderTopRightRadius: 32,
                                    backgroundColor: 'light.secondary'
                                }}/>
                        ) : (
                            <DarkModeOutlined
                                color="secondary"
                                onClick={() => dispatch(UI_ACTION_CREATORS.toggleTheme())}
                                sx={{
                                    padding: 0.6,
                                    fontSize: 36,
                                    cursor: 'pointer',
                                    borderTopLeftRadius: 0,
                                    borderBottomLeftRadius: 32,
                                    borderBottomRightRadius: 32,
                                    borderTopRightRadius: 32,
                                    backgroundColor: 'light.secondary'
                                }}
                            />
                        )}
                    </Box>
                </Grid>
            </Grid>
        </Toolbar>
    )
}
export default DesktopHeader;
