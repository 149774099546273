import vision from "./../assets/images/logo/vision.png";
import mission from "./../assets/images/logo/mission.png";

import darkoelectric from "./../assets/images/clients/darkoelectric.png";
import cliftonhomes from "./../assets/images/clients/cliftonhomes.png";
import eastairport from "./../assets/images/clients/eastairport.png";
import thegreens from "./../assets/images/clients/thegreens.png";
import fiestaroyal from "./../assets/images/clients/Fiesta-Royale-Logo-Blue.png";
import globallighting from "./../assets/images/clients/globallightening.png";
import quao from "./../assets/images/clients/QUAO REALTY.png";

const SERVICES = [
    "steel structures",
    "steel trusses",
    "Street light poles",
    "Wood cladding",
    "wood and metal stair railings",
    "Balustrades",
    "Burglarproof",
    "Security gates and doors",
    "Metal furniture",
    "garage doors",
    "Collapsible grills",
    "Aluminum cast designs",
    "Repair and maintenance",
    "Wooden doors",
];

const MISSION = {
    image: mission,
    text: "Our mission is to provide exceptional services and deliver high-quality, cost-effective projects on schedule to meet or exceed the expectation of our customers.\n" +
        "Fulfillment of customers' needs with a suitable level of quality is our main objective and essential element.",
    title: "Mission"
};
const VISION = {
    image: vision,
    text: "Our vision is to be recognized as a leading global company in metal fabrication and woodworking.",
    title: "Vision"
};

const WHY_CHOOSE_US = [
    "We are schedule-driven",
    "We manage complex projects exceptionally well",
    "We make communication with all parties a top priority",
    "We always have hands-on management for your project",
    "We are flexible and versatile"
];

const VALUES = [
    {title: "Integrity and honesty", description: "We do what we say we will do"},
    {title: "Commitment to success", description: "We endeavor to exceed customer expectations"},
    {title: "Virtue", description: "We believe in being an upright and dependable company that focuses on quality"},
    {
        title: "Excellence",
        description: "Ingenuity to execute our work so that we provide a high-quality finished product and superb customer service"
    },
    {title: "Safety"}
];

const TEAM = [
    {department: "ADMINISTRATION", position: "Secretary/Receptionist", name: "Faith Ahadzi"},
    {department: "ADMINISTRATION", position: "Administrative Manager", name: "Augustina Aikon"},
    {department: "ADMINISTRATION", position: "Accountant", name: "Mr. Yeboah"},
    {department: "ADMINISTRATION", position: "Project Manager", name: "Wilfred Aikon"},
]

const CLIENTS = [
    {name: "Darko Electric", logo: darkoelectric},
    {name: "Clifton Homes", logo: cliftonhomes},
    {name: "East Airport Int. School", logo: eastairport},
    {name: "The Greens GH", logo: thegreens},
    {name: "Fiesta Royale", logo: fiestaroyal},
    {name: "Global Lighting", logo: globallighting},
    {name: " QUAO REALTY", logo: quao},
]

export const SUPER_CRAFT_GH_DATA = {SERVICES, MISSION, VISION, WHY_CHOOSE_US, VALUES, TEAM, CLIENTS}
