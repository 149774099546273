import {Box, Card, CardContent, Container, Divider, Grid, Link as MUILink, Stack, Typography} from "@mui/material";
import {Element} from "react-scroll";
import Layout from "../../components/layout/layout";
import {
    CalendarToday,
    Call,
    Close,
    Email,
    FacebookOutlined,
    Instagram,
    LocalPostOffice,
    LocationCity,
    Mail,
    Phone,
    Schedule
} from "@mui/icons-material";
import {SUPER_CRAFT_GH_DATA} from "../../utils/data";
import Service from "../../components/shared/service";
import Mission from "../../components/shared/mission";
import Choice from "../../components/shared/choice";
import CoreValue from "../../components/shared/core-value";
import Team from "../../components/shared/team";
import Carousel from "react-multi-carousel";
import Client from "../../components/shared/client";
import {UTILS} from "../../utils/utils";
import ContactCard from "../../components/shared/contact-card";
import ContactForm from "../../components/shared/contact-form";
import IconTextview from "../../components/shared/icon-textview";
import {motion} from "framer-motion";
import banner from "./../../assets/images/banners/banner.jpg";
import {useSelector} from "react-redux";
import {selectUI} from "../../redux/features/ui/ui-slice";

const container = {
    hidden: {
        opacity: 0,
        x: '-5vw',
    },
    visible: {
        opacity: 1,
        x: 0,
        transition: {
            staggerChildren: 0.3,
            staggerDirection: -1,
            stiffness: 150,
            bounce: 1,
            when: "beforeChildren",
            duration: 0.5,
            type: "spring"
        }
    },

};

const item = {
    hidden: {
        opacity: 0,
        x: '-5vw',
        transition: {
            duration: 0.3
        }
    },
    visible: {
        opacity: 1,
        x: 0,
        transition: {
            stiffness: 150,
            bounce: 1,
            duration: 0.3
        }
    },
    hover: {
        scale: 1.1,
        transition: {
            duration: 0.3,
            repeat: Infinity,
            repeatType: "reverse",
            delay: 0.2
        }
    }
};

const gridContainer = {
    hidden: {
        opacity: 0,
        x: 50,
    },
    visible: {
        opacity: 1,
        x: 0,
        transition: {
            duration: 1,
            delayChildren: 0.5,
            staggerChildren: 0.2,
            when: "beforeChildren",
            stiffness: 250,
            bounce: 0.3,
            type: "spring"
        }
    },
};

const gridItem = {
    hidden: {
        opacity: 0,
        transition: {
            duration: 0.3,
            stiffness: 250,
            bounce: 0.3,
            type: "spring"
        }
    },
    visible: {
        opacity: 1,
        transition: {
            duration: 0.3,
            stiffness: 250,
            bounce: 0.3
        }
    },
    hover: {
        scale: 1.05,
        transition: {
            duration: 0.3,
            repeat: Infinity,
            repeatType: "reverse",
            delay: 0.1
        }
    }
};

const IndexPage = () => {

    const {theme} = useSelector(selectUI);

    return (
        <Layout>
            <Box>

                <Element id="home" name="home">
                    <Box sx={{position: "relative", minHeight: {xs: "50vh", lg: "100vh"}}}>
                        <Box
                            sx={{
                                minHeight: {xs: "50vh", lg: "100vh"},
                                backgroundImage: `url(${banner})`,
                                backgroundBlendMode: "overlay",
                                backgroundPosition: "center",
                                backgroundSize: "cover",
                                backgroundRepeat: "no-repeat",
                                backgroundAttachment: "scroll",
                                backgroundColor: theme === 'dark' ? "rgba(0, 0, 0, 0.35)" : "rgba(255, 255, 255, 0.4)",
                                position: "absolute",
                                width: "100%",
                                height: "100%",
                                top: 0,
                                left: 0,
                                content: `""`
                            }}>
                            <Grid
                                sx={{height: {xs: "50vh", lg: "100vh"}}}
                                container={true}
                                justifyContent="space-between"
                                alignItems="center">
                                <Grid item={true} xs={false} lg={2}>
                                    <Box sx={{
                                        display: {xs: "none", lg: "flex"},
                                        alignItems: "center",
                                        justifyContent: "center",
                                    }}>
                                        <Divider
                                            sx={{height: "70vh"}}
                                            flexItem={true}
                                            variant="middle"
                                            orientation="vertical">
                                            <Stack
                                                variants={container}
                                                initial="hidden"
                                                whileInView="visible"
                                                viewport={{once: true}}
                                                component={motion.div}
                                                direction="column"
                                                spacing={2}>
                                                <Box
                                                    whileHover="hover"
                                                    variants={item}
                                                    component={motion.div}>
                                                    <MUILink href="" target="_blank">
                                                        <FacebookOutlined
                                                            color={theme === 'light' ? 'primary' : 'secondary'}
                                                            sx={{
                                                                padding: 0.6,
                                                                fontSize: 36,
                                                                cursor: 'pointer',
                                                                borderTopLeftRadius: 0,
                                                                borderBottomLeftRadius: 32,
                                                                borderBottomRightRadius: 32,
                                                                borderTopRightRadius: 32,
                                                                backgroundColor: theme === 'light' ? 'light.primary' : 'light.secondary'
                                                            }}/>
                                                    </MUILink>
                                                </Box>

                                                <Box
                                                    whileHover="hover"
                                                    variants={item}
                                                    component={motion.div}>
                                                    <MUILink href="" target="_blank">
                                                        <Instagram
                                                            color={theme === 'light' ? 'primary' : 'secondary'}
                                                            sx={{
                                                                padding: 0.6,
                                                                fontSize: 36,
                                                                cursor: 'pointer',
                                                                borderTopLeftRadius: 0,
                                                                borderBottomLeftRadius: 32,
                                                                borderBottomRightRadius: 32,
                                                                borderTopRightRadius: 32,
                                                                backgroundColor: theme === 'light' ? 'light.primary' : 'light.secondary'
                                                            }}/>
                                                    </MUILink>
                                                </Box>

                                                <Box
                                                    whileHover="hover"
                                                    variants={item} component={motion.div}>
                                                    <MUILink href="" target="_blank">
                                                        <Mail
                                                            color={theme === 'light' ? 'primary' : 'secondary'}
                                                            sx={{
                                                                padding: 0.6,
                                                                fontSize: 36,
                                                                cursor: 'pointer',
                                                                borderTopLeftRadius: 0,
                                                                borderBottomLeftRadius: 32,
                                                                borderBottomRightRadius: 32,
                                                                borderTopRightRadius: 32,
                                                                backgroundColor: theme === 'light' ? 'light.primary' : 'light.secondary'
                                                            }}/>
                                                    </MUILink>
                                                </Box>

                                                <Box
                                                    whileHover="hover"
                                                    variants={item} component={motion.div}>
                                                    <MUILink href="" target="_blank">
                                                        <Call
                                                            color={theme === 'light' ? 'primary' : 'secondary'}
                                                            sx={{
                                                                padding: 0.6,
                                                                fontSize: 36,
                                                                cursor: 'pointer',
                                                                borderTopLeftRadius: 0,
                                                                borderBottomLeftRadius: 32,
                                                                borderBottomRightRadius: 32,
                                                                borderTopRightRadius: 32,
                                                                backgroundColor: theme === 'light' ? 'light.primary' : 'light.secondary'
                                                            }}/>
                                                    </MUILink>
                                                </Box>
                                            </Stack>
                                        </Divider>
                                    </Box>
                                </Grid>
                                <Grid item={true} xs={12} lg={5}>
                                    <Container
                                        sx={{
                                            display: 'flex',
                                            alignItems: {xs: "center", lg: "flex-start"},
                                            flexDirection: "column",
                                            height: '100%',
                                            justifyContent: 'center'
                                        }}>
                                        <Typography
                                            component={motion.h2}
                                            whileHover={{
                                                scale: 1.1,
                                                textShadow: "2px 5px 5px #f9a34f",
                                                originX: 0,
                                                transition: {
                                                    delay: 0.1,
                                                    duration: 0.5,
                                                    repeat: Infinity,
                                                    repeatType: "mirror"
                                                }
                                            }}
                                            viewport={{once: true}}
                                            whileInView={{y: 0, opacity: 1}}
                                            initial={{y: '10vh', opacity: 0}}
                                            transition={{
                                                delay: 0.5,
                                                duration: 1,
                                                type: 'spring',
                                                bounce: 0.5,
                                                stiffness: 150
                                            }}
                                            sx={{
                                                color: 'text.primary',
                                                fontWeight: 'bolder',
                                                textAlign: {xs: "center", lg: "left"},
                                                mb: 2,
                                                fontSize: {xs: 40, lg: 72}
                                            }}
                                            variant="h2">Super Craft</Typography>
                                        <Typography
                                            whileInView={{y: 0, opacity: 1}}
                                            initial={{y: '-10vh', opacity: 0}}
                                            transition={{
                                                delay: 0.1,
                                                duration: 0.5,
                                                type: 'spring',
                                                bounce: 0.9,
                                                stiffness: 150
                                            }}
                                            viewport={{once: true}}
                                            whileHover={{
                                                originX: 0,
                                                transition: {
                                                    delay: 0.1,
                                                    duration: 0.5
                                                }
                                            }}
                                            component={motion.h5}
                                            sx={{color: 'primary.text', textAlign: {xs: "center", lg: "left"}}}
                                            variant="h5">
                                            The home of blacksmith
                                        </Typography>
                                    </Container>
                                </Grid>
                            </Grid>
                        </Box>
                    </Box>
                    <Box sx={{backgroundColor: "secondary.main", py: 2}}>
                        <Container maxWidth="xl">
                            <Typography
                                component={motion.p}
                                initial={{opacity: 0}}
                                whileInView={{opacity: 1}}
                                transition={{duration: 1}}
                                viewport={{once: true}}
                                align="center" sx={{color: "text.primary"}} variant="body1">
                                Mr Augustus Aikon is a talented welding engineer with more than 30 years experience
                                in
                                the welding and fabrication. Mr. Aikon is skilled in reading and applying technical
                                drawings .He has also operated various machinery to fabricate items and has
                                consistently
                                delivered high-quality products. He has successfully trained multiple apprentices
                                and
                                currently heads about 25 employees and apprentices.
                            </Typography>
                        </Container>
                    </Box>
                </Element>

                <Element id="services" name="services">
                    <Box sx={{py: 8}}>
                        <Container maxWidth="xl">
                            <Stack direction="column" spacing={4}>
                                <Typography
                                    component={motion.h5}
                                    initial={{opacity: 0}}
                                    whileInView={{opacity: 1}}
                                    transition={{duration: 1}}
                                    viewport={{once: true}}
                                    sx={{color: "text.action", textTransform: "uppercase"}}
                                    variant="h5"
                                    align="center">
                                    What we do
                                </Typography>

                                <Box>
                                    <Grid
                                        variants={gridContainer}
                                        whileInView="visible"
                                        initial="hidden"
                                        component={motion.div}
                                        container={true}
                                        spacing={4}>
                                        {SUPER_CRAFT_GH_DATA.SERVICES.map((service, index) => {
                                            return (
                                                <Grid
                                                    component={motion.div}
                                                    variants={gridItem}
                                                    whileHover="hover"
                                                    key={index}
                                                    item={true}
                                                    xs={12} md={4} lg={3}>
                                                    <Service service={service}/>
                                                </Grid>
                                            )
                                        })}
                                    </Grid>
                                </Box>
                            </Stack>
                        </Container>
                    </Box>
                </Element>

                <Element id="about" name="about">
                    <Box sx={{backgroundColor: "background.alternative", py: 8}}>
                        <Container maxWidth="xl">
                            <Stack direction="column" spacing={2}>
                                <Typography
                                    component={motion.h5}
                                    initial={{opacity: 0}}
                                    whileInView={{opacity: 1}}
                                    transition={{duration: 1}}
                                    viewport={{once: true}}
                                    sx={{color: "text.action", textTransform: "uppercase"}}
                                    variant="h5"
                                    align="center">
                                    Mission & Vision
                                </Typography>

                                <Box>
                                    <Grid container={true} spacing={4}>
                                        <Grid item={true} xs={12} md={6}>
                                            <Box
                                                sx={{height: "100%"}}
                                                initial={{opacity: 0, y: '10vh'}}
                                                whileInView={{opacity: 1, y: 0}}
                                                viewport={{once: true}}
                                                transition={{
                                                    delay: 0.1,
                                                    duration: 0.3,
                                                    bounce: 0.5,
                                                    stiffness: 150,
                                                    type: "spring"
                                                }}
                                                component={motion.div}>
                                                <Mission mission={SUPER_CRAFT_GH_DATA.MISSION}/>
                                            </Box>
                                        </Grid>
                                        <Grid item={true} xs={12} md={6}>
                                            <Box
                                                sx={{height: "100%"}}
                                                initial={{opacity: 0, y: '-10vh'}}
                                                whileInView={{opacity: 1, y: 0}}
                                                transition={{
                                                    delay: 0.1,
                                                    duration: 0.3,
                                                    bounce: 0.5,
                                                    stiffness: 150,
                                                    type: "spring"
                                                }}
                                                component={motion.div}>
                                                <Mission mission={SUPER_CRAFT_GH_DATA.VISION}/>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Stack>
                        </Container>
                    </Box>

                    <Box sx={{py: 8, backgroundColor: "background.default"}}>
                        <Container maxWidth="xl">
                            <Stack direction="column" spacing={2}>
                                <Typography
                                    component={motion.h5}
                                    initial={{opacity: 0}}
                                    whileInView={{opacity: 1}}
                                    transition={{duration: 1}}
                                    viewport={{once: true}}
                                    sx={{color: "text.action", textTransform: "uppercase"}}
                                    variant="h5"
                                    align="center">
                                    Why Choose Us
                                </Typography>

                                <Box>
                                    <Grid
                                        variants={gridContainer}
                                        whileInView="visible"
                                        viewport={{once: true}}
                                        initial="hidden"
                                        component={motion.div}
                                        container={true} spacing={4}>
                                        {SUPER_CRAFT_GH_DATA.WHY_CHOOSE_US.map((choice, index) => {
                                            return (
                                                <Grid
                                                    component={motion.div}
                                                    variants={gridItem}
                                                    whileHover="hover"
                                                    key={index}
                                                    item={true}
                                                    xs={12}
                                                    md={6}>
                                                    <Choice choice={choice}/>
                                                </Grid>
                                            )
                                        })}
                                    </Grid>
                                </Box>
                            </Stack>
                        </Container>
                    </Box>

                    <Box sx={{py: 8, backgroundColor: "background.alternative"}}>
                        <Container maxWidth="xl">
                            <Stack direction="column" spacing={2}>
                                <Typography
                                    component={motion.h5}
                                    initial={{opacity: 0}}
                                    whileInView={{opacity: 1}}
                                    transition={{duration: 1}}
                                    viewport={{once: true}}
                                    sx={{color: "text.action", textTransform: "uppercase"}}
                                    variant="h5"
                                    align="center">
                                    Our Core Values
                                </Typography>

                                <Box>
                                    <Grid
                                        variants={gridContainer}
                                        whileInView="visible"
                                        viewport={{once: true}}
                                        initial="hidden"
                                        component={motion.div}
                                        container={true} spacing={4}>
                                        {SUPER_CRAFT_GH_DATA.VALUES.map((value, index) => {
                                            return (
                                                <Grid
                                                    component={motion.div}
                                                    variants={gridItem}
                                                    whileHover="hover"
                                                    key={index} item={true} xs={12} md={6}>
                                                    <CoreValue value={value}/>
                                                </Grid>
                                            )
                                        })}
                                    </Grid>
                                </Box>
                            </Stack>
                        </Container>
                    </Box>

                    <Box sx={{py: 8, backgroundColor: "background.default"}}>
                        <Container maxWidth="xl">
                            <Stack direction="column" spacing={2}>
                                <Typography
                                    sx={{color: "text.action", textTransform: "uppercase"}}
                                    variant="h5"
                                    align="center">
                                    Profile
                                </Typography>
                                <Typography
                                    component={motion.p}
                                    initial={{opacity: 0}}
                                    whileInView={{opacity: 1}}
                                    transition={{duration: 1}}
                                    viewport={{once: true}}
                                    sx={{color: "text.secondary", textTransform: "none"}}
                                    variant="body1">
                                    Super Craft Wrought Iron and Wood Works Ltd is a metal fabrication and woodcraft
                                    company. We are also producers of structural and miscellaneous steel components
                                    in
                                    Ghana. Complex projects are our specialty; as a result, no project is burdensome
                                    for
                                    us.
                                </Typography>
                                <Typography
                                    component={motion.p}
                                    initial={{opacity: 0}}
                                    whileInView={{opacity: 1}}
                                    transition={{duration: 1}}
                                    viewport={{once: true}}
                                    sx={{color: "text.secondary", textTransform: "none"}}
                                    variant="body1">
                                    Working with Super Craft means working with a skilled team that has profound
                                    knowledge in our line of work. From pricing and estimates to drawings and
                                    designs,
                                    welding engineers, and the general workforce.
                                    We are proud of our accomplishments and have great confidence in our work
                                </Typography>
                            </Stack>
                        </Container>
                    </Box>

                    <Box sx={{py: 8, backgroundColor: "background.alternative"}}>
                        <Container maxWidth="xl">
                            <Stack direction="column" spacing={2}>
                                <Typography
                                    component={motion.h5}
                                    initial={{opacity: 0}}
                                    whileInView={{opacity: 1}}
                                    transition={{duration: 1}}
                                    viewport={{once: true}}
                                    sx={{color: "text.action", textTransform: "uppercase"}}
                                    variant="h5"
                                    align="center">
                                    Our Team
                                </Typography>

                                <Box>
                                    <Grid
                                        variants={gridContainer}
                                        whileInView="visible"
                                        viewport={{once: true}}
                                        initial="hidden"
                                        component={motion.div}
                                        container={true} spacing={4}>
                                        {SUPER_CRAFT_GH_DATA.TEAM.map((team, index) => {
                                            return (
                                                <Grid
                                                    component={motion.div}
                                                    variants={gridItem}
                                                    whileHover="hover"
                                                    key={index}
                                                    item={true}
                                                    xs={12}
                                                    md={6}>
                                                    <Team team={team}/>
                                                </Grid>
                                            )
                                        })}
                                    </Grid>
                                </Box>
                            </Stack>
                        </Container>
                    </Box>

                    <Box sx={{py: 8, backgroundColor: "background.default"}}>
                        <Container maxWidth="xl">
                            <Stack direction="column" spacing={2}>
                                <Typography
                                    component={motion.h5}
                                    initial={{opacity: 0}}
                                    whileInView={{opacity: 1}}
                                    transition={{duration: 1}}
                                    viewport={{once: true}}
                                    sx={{color: "text.action", textTransform: "uppercase"}}
                                    variant="h5"
                                    align="center">
                                    Our Clients
                                </Typography>

                                <Carousel
                                    infinite={true}
                                    autoPlay={true}
                                    autoPlaySpeed={3000}
                                    responsive={UTILS.responsive}>
                                    {SUPER_CRAFT_GH_DATA.CLIENTS.map((client, index) => {
                                        return (
                                            <Box sx={{mx: 2, py: 2}} key={index}>
                                                <Client client={client}/>
                                            </Box>
                                        )
                                    })}
                                </Carousel>
                            </Stack>
                        </Container>
                    </Box>

                </Element>

                <Element id="contact" name="contact">
                    <Box sx={{py: 8, backgroundColor: "background.alternative"}}>
                        <Container maxWidth="xl">
                            <Typography
                                component={motion.h5}
                                initial={{opacity: 0}}
                                whileInView={{opacity: 1}}
                                transition={{duration: 1}}
                                viewport={{once: true}}
                                sx={{color: "text.action", textTransform: "uppercase"}}
                                variant="h5"
                                align="center">
                                Contact Us
                            </Typography>

                            <Box mt={8}>
                                <Container>
                                    <Grid container={true} spacing={3}>
                                        <Grid item={true} xs={12} sm={6} md={3}>
                                            <Box
                                                initial={{opacity: 0, x: '-10vw'}}
                                                whileInView={{opacity: 1, x: 0}}
                                                transition={{delay: 0.3, duration: 1}}
                                                viewport={{once: true}}
                                                sx={{height: "100%"}}
                                                component={motion.div}>
                                                <ContactCard
                                                    link={`mailto://info@supercraftgh.com`}
                                                    icon={<Email
                                                        sx={{
                                                            color: "secondary.main",
                                                            backgroundColor: 'light.secondary',
                                                            borderTopLeftRadius: 0,
                                                            borderBottomLeftRadius: 32,
                                                            borderBottomRightRadius: 32,
                                                            borderTopRightRadius: 32,
                                                            padding: 0.6,
                                                            fontSize: 36,
                                                            '&:hover': {
                                                                color: 'secondary.main',
                                                                transition: '300ms ease-in-out'
                                                            }
                                                        }}/>}
                                                    label="Email"
                                                    value="info@supercraftgh.com"
                                                />
                                            </Box>
                                        </Grid>
                                        <Grid item={true} xs={12} sm={6} md={3}>
                                            <Box
                                                initial={{opacity: 0, y: '10vh'}}
                                                whileInView={{opacity: 1, y: 0}}
                                                viewport={{once: true}}
                                                transition={{delay: 0.3, duration: 1}}
                                                sx={{height: "100%"}}
                                                component={motion.div}>
                                                <ContactCard
                                                    link={`tel://+233501597254`}
                                                    icon={<Phone
                                                        sx={{
                                                            color: "secondary.main",
                                                            backgroundColor: 'light.secondary',
                                                            borderTopLeftRadius: 0,
                                                            borderBottomLeftRadius: 32,
                                                            borderBottomRightRadius: 32,
                                                            borderTopRightRadius: 32,
                                                            padding: 0.6,
                                                            fontSize: 36,
                                                            '&:hover': {
                                                                color: 'secondary.main',
                                                                transition: '300ms ease-in-out'
                                                            }
                                                        }}/>}
                                                    label="Phone"
                                                    value="+233501597254"
                                                />
                                            </Box>
                                        </Grid>
                                        <Grid item={true} xs={12} sm={6} md={3}>
                                            <Box
                                                initial={{opacity: 0, y: '-10vh'}}
                                                whileInView={{opacity: 1, y: 0}}
                                                transition={{delay: 0.3, duration: 1}}
                                                viewport={{once: true}}
                                                sx={{height: "100%"}}
                                                component={motion.div}>
                                                <ContactCard
                                                    icon={<LocationCity
                                                        sx={{
                                                            color: "secondary.main",
                                                            backgroundColor: 'light.secondary',
                                                            borderTopLeftRadius: 0,
                                                            borderBottomLeftRadius: 32,
                                                            borderBottomRightRadius: 32,
                                                            borderTopRightRadius: 32,
                                                            padding: 0.6,
                                                            fontSize: 36,
                                                            '&:hover': {
                                                                color: 'secondary.main',
                                                                transition: '300ms ease-in-out'
                                                            }
                                                        }}/>}
                                                    label="Address"
                                                    value="Oyarifa - Yellow House(Aburi road)"
                                                />
                                            </Box>
                                        </Grid>
                                        <Grid item={true} xs={12} sm={6} md={3}>
                                            <Box
                                                initial={{opacity: 0, x: '10vw'}}
                                                whileInView={{opacity: 1, x: 0}}
                                                viewport={{once: true}}
                                                transition={{delay: 0.3, duration: 1}}
                                                sx={{height: "100%"}}
                                                component={motion.div}>
                                                <ContactCard
                                                    icon={<LocalPostOffice
                                                        sx={{
                                                            color: "secondary.main",
                                                            backgroundColor: 'light.secondary',
                                                            borderTopLeftRadius: 0,
                                                            borderBottomLeftRadius: 32,
                                                            borderBottomRightRadius: 32,
                                                            borderTopRightRadius: 32,
                                                            padding: 0.6,
                                                            fontSize: 36,
                                                            '&:hover': {
                                                                color: 'secondary.main',
                                                                transition: '300ms ease-in-out'
                                                            }
                                                        }}/>}
                                                    label="Post Office"
                                                    value="P.O.Box AF 1094,Adenta-Accra"/>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Container>
                            </Box>

                            <Box mt={8}>
                                <Container>
                                    <Grid container={true} spacing={4} justifyContent="space-around">
                                        <Grid item={true} xs={12} md={8}>
                                            <Box
                                                initial={{opacity: 0, x: '-10vw'}}
                                                whileInView={{opacity: 1, x: 0}}
                                                viewport={{once: true}}
                                                transition={{delay: 0.3, duration: 1, bounce: 1, stiffness: 150}}
                                                component={motion.div}>
                                                <ContactForm
                                                    title="Send Message"
                                                    caption="Send us a message and our customer support will help you find answers to your questions."
                                                />
                                            </Box>
                                        </Grid>
                                        <Grid item={true} xs={12} md={4}>
                                            <Box
                                                initial={{opacity: 0, x: '10vw'}}
                                                whileInView={{opacity: 1, x: 0}}
                                                viewport={{once: true}}
                                                transition={{delay: 0.3, duration: 1, bounce: 1, stiffness: 150}}
                                                component={motion.div}>
                                                <Card
                                                    sx={{
                                                        borderTopLeftRadius: 0,
                                                        borderBottomLeftRadius: 32,
                                                        borderBottomRightRadius: 32,
                                                        borderTopRightRadius: 32,
                                                    }} elevation={0}>
                                                    <CardContent
                                                        variants={gridContainer}
                                                        whileInView="visible"
                                                        viewport={{once: true}}
                                                        initial="hidden"
                                                        component={motion.div}>
                                                        <Typography
                                                            component={motion.h5}
                                                            variants={gridItem}
                                                            whileHover="hover"
                                                            variant="h5" mb={2}>Open Hours</Typography>
                                                        <Typography
                                                            component={motion.p}
                                                            variants={gridItem}
                                                            whileHover="hover"
                                                            sx={{my: 2, color: "text.primary"}}
                                                            variant="body1">
                                                            Working Hours
                                                        </Typography>
                                                        <Box component={motion.div} variants={gridItem}
                                                             whileHover="hover">
                                                            <IconTextview
                                                                icon={<Schedule
                                                                    sx={{
                                                                        color: 'secondary.main',
                                                                        backgroundColor: 'light.secondary',
                                                                        borderTopLeftRadius: 0,
                                                                        borderBottomLeftRadius: 32,
                                                                        borderBottomRightRadius: 32,
                                                                        borderTopRightRadius: 32,
                                                                        padding: 0.6,
                                                                        fontSize: 36,
                                                                        '&:hover': {
                                                                            color: 'secondary.main',
                                                                            transition: '300ms ease-in-out'
                                                                        }
                                                                    }}/>} text="8:30 AM - 5:30 PM"/>
                                                        </Box>
                                                        <Typography
                                                            component={motion.p}
                                                            variants={gridItem}
                                                            whileHover="hover"
                                                            sx={{my: 2, color: "text.primary"}} variant="body1">
                                                            Working Days
                                                        </Typography>
                                                        <Box component={motion.div} variants={gridItem}
                                                             whileHover="hover">
                                                            <IconTextview icon={<CalendarToday
                                                                sx={{
                                                                    color: "secondary.main",
                                                                    backgroundColor: 'light.secondary',
                                                                    borderTopLeftRadius: 0,
                                                                    borderBottomLeftRadius: 32,
                                                                    borderBottomRightRadius: 32,
                                                                    borderTopRightRadius: 32,
                                                                    padding: 0.6,
                                                                    fontSize: 36,
                                                                    '&:hover': {
                                                                        color: 'secondary.main',
                                                                        transition: '300ms ease-in-out'
                                                                    }
                                                                }}/>} text="Monday - Friday"/>
                                                        </Box>
                                                        <Typography
                                                            component={motion.p}
                                                            variants={gridItem}
                                                            whileHover="hover"
                                                            sx={{my: 2, color: "text.primary"}}
                                                            variant="body1">
                                                            Saturday
                                                        </Typography>
                                                        <Box component={motion.div} variants={gridItem}
                                                             whileHover="hover">
                                                            <IconTextview icon={<Schedule
                                                                sx={{
                                                                    color: "secondary.main",
                                                                    backgroundColor: 'light.secondary',
                                                                    borderTopLeftRadius: 0,
                                                                    borderBottomLeftRadius: 32,
                                                                    borderBottomRightRadius: 32,
                                                                    borderTopRightRadius: 32,
                                                                    padding: 0.6,
                                                                    fontSize: 36,
                                                                    '&:hover': {
                                                                        color: 'secondary.main',
                                                                        transition: '300ms ease-in-out'
                                                                    }
                                                                }}/>} text="Closed"/>
                                                        </Box>
                                                        <Typography
                                                            component={motion.p}
                                                            variants={gridItem}
                                                            whileHover="hover"
                                                            sx={{my: 2, color: "text.primary"}}
                                                            variant="body1">
                                                            Sunday
                                                        </Typography>
                                                        <Box component={motion.div} variants={gridItem}
                                                             whileHover="hover">
                                                            <IconTextview icon={<Close
                                                                sx={{
                                                                    color: "secondary.main",
                                                                    backgroundColor: 'light.secondary',
                                                                    borderTopLeftRadius: 0,
                                                                    borderBottomLeftRadius: 32,
                                                                    borderBottomRightRadius: 32,
                                                                    borderTopRightRadius: 32,
                                                                    padding: 0.6,
                                                                    fontSize: 36,
                                                                    '&:hover': {
                                                                        color: 'secondary.main',
                                                                        transition: '300ms ease-in-out'
                                                                    }
                                                                }}/>} text="Closed"
                                                            />
                                                        </Box>
                                                        <Typography
                                                            component={motion.p}
                                                            variants={gridItem}
                                                            whileHover="hover"
                                                            sx={{my: 2, color: "text.primary"}}
                                                            variant="body1">
                                                            Holidays
                                                        </Typography>
                                                        <Box component={motion.div} variants={gridItem}
                                                             whileHover="hover">
                                                            <IconTextview icon={<Schedule
                                                                sx={{
                                                                    color: "secondary.main",
                                                                    backgroundColor: 'light.secondary',
                                                                    borderTopLeftRadius: 0,
                                                                    borderBottomLeftRadius: 32,
                                                                    borderBottomRightRadius: 32,
                                                                    borderTopRightRadius: 32,
                                                                    padding: 0.6,
                                                                    fontSize: 36,
                                                                    '&:hover': {
                                                                        color: 'secondary.main',
                                                                        transition: '300ms ease-in-out'
                                                                    }
                                                                }}/>} text="Closed"/>
                                                        </Box>
                                                    </CardContent>
                                                </Card>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Container>
                            </Box>
                        </Container>
                    </Box>
                </Element>
            </Box>
        </Layout>
    )
}

export default IndexPage;
