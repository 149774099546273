import {CONSTANTS} from "../../../utils/constants";
import {createSlice} from "@reduxjs/toolkit";

const INITIAL_STATE = {
    theme: localStorage.getItem(CONSTANTS.SUPER_CRAFT_GH_THEME) ? JSON.parse(localStorage.getItem(CONSTANTS.SUPER_CRAFT_GH_THEME)): 'dark',
    drawerOpen: false
}

const uiSlice = createSlice({
    name: "ui",
    initialState: INITIAL_STATE,
    reducers: {
        toggleTheme: (state) => {
            state.theme = state.theme === 'dark' ? 'light': 'dark';
            localStorage.setItem(CONSTANTS.SUPER_CRAFT_GH_THEME, JSON.stringify(state.theme === 'dark' ? 'light': 'dark'));
        },
        toggleDrawer: (state, action) => {
            state.drawerOpen = action.payload;
        }
    }
});

const {actions, reducer} = uiSlice;
export const UI_ACTION_CREATORS = {toggleTheme: actions.toggleTheme, toggleDrawer: actions.toggleDrawer};
export const selectUI = state => state.ui;
export default reducer;
