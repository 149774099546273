import {Box, CardMedia, Container, Divider, Grid, Stack, Typography} from "@mui/material";
import IconTextview from "../shared/icon-textview";
import {
    ContactPage,
    DetailsOutlined,
    HomeOutlined,
    LocalPostOffice,
    LocationCity,
    Mail,
    MiscellaneousServicesOutlined,
    Phone
} from "@mui/icons-material";
import {AnimatePresence, motion} from "framer-motion";
import {Link as ScrollLink} from "react-scroll";
import React from "react";
import lightBigLogo from "../../assets/images/logo/logo-light-big.png";
import darkBigLogo from "../../assets/images/logo/logo-dark-big.png";
import {useSelector} from "react-redux";
import {selectUI} from "../../redux/features/ui/ui-slice";


const container = {
    hidden: {
        opacity: 0,
        x: '-10vh'
    },
    visible: {
        opacity: 1,
        x: 0,
        transition: {
            bounce: 1,
            staggerChildren: 0.4,
            when: "beforeChildren",
            stiffness: 150
        }
    }
};

const item = {
    hidden: {
        opacity: 0,
        x: '-10vh'
    },
    visible: {
        opacity: 1,
        x: 0,
        transition: {
            bounce: 1,
            stiffness: 150
        }
    },
};

const DesktopFooter = () => {

    const {theme} =  useSelector(selectUI);

    return (
        <Box sx={{backgroundColor: 'background.paper', paddingY: 4}}>
            <Container maxWidth="xl">
                <Grid container={true} spacing={3}>
                    <Grid item={true} xs={3}>
                        <AnimatePresence initial={true} presenceAffectsLayout={true} mode="popLayout">
                            {theme === 'light' && (
                                <Stack
                                    whileInView={{x: 0, opacity: 1}}
                                    initial={{x: '10vw', opacity: 0}}
                                    exit={{x: '-100vw', opacity: 0}}
                                    transition={{
                                        delay: 0.1,
                                        duration: 0.3,
                                        type: 'spring',
                                        bounce: 0.9,
                                        stiffness: 150
                                    }}
                                    whileHover={{
                                        scale: 1.2,
                                        transition: {
                                            duration: 0.5,
                                            repeat: Infinity,
                                            repeatType: "reverse"
                                        }
                                    }}
                                    component={motion.div}
                                    sx={{height: "100%"}}
                                    direction="row"
                                    justifyContent="center"
                                    alignItems="center">
                                    <CardMedia
                                        component="img"
                                        src={lightBigLogo}
                                        sx={{width: 200, height: "100%", objectFit: "contain", maxWidth: "100%"}}
                                    />
                                </Stack>
                            )}
                        </AnimatePresence>
                        <AnimatePresence initial={true} presenceAffectsLayout={true} mode="popLayout">
                            {theme === 'dark' && (
                                <Stack
                                    whileInView={{x: 0, opacity: 1}}
                                    initial={{x: '10vw', opacity: 0}}
                                    exit={{x: '-100vw', opacity: 0}}
                                    transition={{
                                        delay: 0.1,
                                        duration: 0.3,
                                        type: 'spring',
                                        bounce: 0.9,
                                        stiffness: 150
                                    }}
                                    whileHover={{
                                        scale: 1.2,
                                        transition: {
                                            duration: 0.5,
                                            repeat: Infinity,
                                            repeatType: "reverse"
                                        }
                                    }}
                                    component={motion.div}
                                    sx={{height: "100%"}}
                                    direction="row"
                                    justifyContent="center"
                                    alignItems="center">
                                    <CardMedia
                                        component="img"
                                        src={darkBigLogo}
                                        sx={{width: 200, height: "100%", objectFit: "contain", maxWidth: "100%"}}
                                    />
                                </Stack>
                            )}
                        </AnimatePresence>
                    </Grid>
                    <Grid item={true} xs={3}>
                        <Typography variant="h6" sx={{color: 'text.primary'}}>
                            Quick Links
                        </Typography>

                        <Divider variant="fullWidth" sx={{my: 2}} light={true}/>

                        <Stack
                            sx={{mb: 4}}
                            component={motion.div}
                            variants={container}
                            animate="visible"
                            initial="hidden"
                            direction="column"
                            spacing={2}>
                            <Box component={motion.div} variants={item}>
                                <ScrollLink
                                    offset={-70}
                                    spy={true}
                                    smooth={true}
                                    duration={2000}
                                    delay={500} to="home">
                                    <IconTextview
                                        icon={
                                            <HomeOutlined
                                                sx={{
                                                    color: "secondary.main",
                                                    borderTopLeftRadius: 0,
                                                    borderBottomLeftRadius: 32,
                                                    borderBottomRightRadius: 32,
                                                    borderTopRightRadius: 32,
                                                    padding: 0.4,
                                                    fontSize: 28,
                                                    backgroundColor: 'light.secondary'
                                                }}/>}
                                        text="Home"
                                    />
                                </ScrollLink>
                            </Box>
                            <Box component={motion.div} variants={item}>
                                <ScrollLink
                                    offset={-70}
                                    spy={true}
                                    smooth={true}
                                    duration={2000}
                                    delay={500}
                                    to="services">
                                    <IconTextview
                                        icon={
                                            <MiscellaneousServicesOutlined
                                                sx={{
                                                    color: "secondary.main",
                                                    borderTopLeftRadius: 0,
                                                    borderBottomLeftRadius: 32,
                                                    borderBottomRightRadius: 32,
                                                    borderTopRightRadius: 32,
                                                    padding: 0.4,
                                                    fontSize: 28,
                                                    backgroundColor: 'light.secondary'
                                                }}/>}
                                        text="Services"
                                    />
                                </ScrollLink>
                            </Box>
                            <Box component={motion.div} variants={item}>
                                <ScrollLink
                                    offset={-70}
                                    spy={true}
                                    smooth={true}
                                    duration={2000}
                                    delay={500}
                                            to="about">
                                    <IconTextview
                                        icon={
                                            <DetailsOutlined
                                                sx={{
                                                    color: "secondary.main",
                                                    borderTopLeftRadius: 0,
                                                    borderBottomLeftRadius: 32,
                                                    borderBottomRightRadius: 32,
                                                    borderTopRightRadius: 32,
                                                    padding: 0.4,
                                                    fontSize: 28,
                                                    backgroundColor: 'light.secondary'
                                                }}/>}
                                        text="About"
                                    />
                                </ScrollLink>
                            </Box>
                            <Box component={motion.div} variants={item}>
                                <ScrollLink offset={-70} spy={true} smooth={true} duration={2000} delay={500}
                                            to="contact">
                                    <IconTextview
                                        icon={
                                            <ContactPage
                                                sx={{
                                                    color: "secondary.main",
                                                    borderTopLeftRadius: 0,
                                                    borderBottomLeftRadius: 32,
                                                    borderBottomRightRadius: 32,
                                                    borderTopRightRadius: 32,
                                                    padding: 0.4,
                                                    fontSize: 28,
                                                    backgroundColor: 'light.secondary'
                                                }}/>}
                                        text="Contact"
                                    />
                                </ScrollLink>
                            </Box>
                        </Stack>
                    </Grid>
                    <Grid item={true} xs={3}>
                        <Typography variant="h6" sx={{color: 'text.primary'}}>
                            Locate Us
                        </Typography>

                        <Divider variant="fullWidth" sx={{my: 2}} light={true}/>
                        <Stack spacing={2}>
                            <IconTextview icon={<LocationCity
                                sx={{
                                    color: "secondary.main",
                                    backgroundColor: 'light.secondary',
                                    borderTopLeftRadius: 0,
                                    borderBottomLeftRadius: 32,
                                    borderBottomRightRadius: 32,
                                    borderTopRightRadius: 32,
                                    padding: 0.6,
                                    fontSize: 36,
                                    '&:hover': {
                                        color: 'secondary.main',
                                        transition: '300ms ease-in-out'
                                    }
                                }}/>} text="Oyarifa - Yellow House(Aburi road)"/>

                            <IconTextview icon={<LocalPostOffice
                                sx={{
                                    color: "secondary.main",
                                    backgroundColor: 'light.secondary',
                                    borderTopLeftRadius: 0,
                                    borderBottomLeftRadius: 32,
                                    borderBottomRightRadius: 32,
                                    borderTopRightRadius: 32,
                                    padding: 0.6,
                                    fontSize: 36,
                                    '&:hover': {
                                        color: 'secondary.main',
                                        transition: '300ms ease-in-out'
                                    }
                                }}/>} text="P.O.Box AF 1094,Adenta-Accra"/>
                        </Stack>
                    </Grid>
                    <Grid item={true} xs={3}>
                        <Typography variant="h6" sx={{color: 'text.primary'}}>
                            Contact Us
                        </Typography>

                        <Divider variant="fullWidth" sx={{my: 2}} light={true}/>

                        <Stack spacing={2}>
                            <IconTextview icon={<Mail
                                sx={{
                                    color: "secondary.main",
                                    backgroundColor: 'light.secondary',
                                    borderTopLeftRadius: 0,
                                    borderBottomLeftRadius: 32,
                                    borderBottomRightRadius: 32,
                                    borderTopRightRadius: 32,
                                    padding: 0.6,
                                    fontSize: 36,
                                    '&:hover': {
                                        color: 'secondary.main',
                                        transition: '300ms ease-in-out'
                                    }
                                }}/>} text="info@supercraftgh.com"/>

                            <IconTextview icon={<Phone
                                sx={{
                                    color: "secondary.main",
                                    backgroundColor: 'light.secondary',
                                    borderTopLeftRadius: 0,
                                    borderBottomLeftRadius: 32,
                                    borderBottomRightRadius: 32,
                                    borderTopRightRadius: 32,
                                    padding: 0.6,
                                    fontSize: 36,
                                    '&:hover': {
                                        color: 'secondary.main',
                                        transition: '300ms ease-in-out'
                                    }
                                }}/>} text="+233 (50) 159 7254"/>
                        </Stack>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    )
}

export default DesktopFooter;
