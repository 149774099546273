import {Box, Button, Container, Stack, Typography} from "@mui/material";
import {Close, ContactPageOutlined, DesignServicesOutlined, HomeOutlined, InfoOutlined} from "@mui/icons-material";
import {useDispatch} from "react-redux";
import SideBarLinkItem from "../shared/side-bar-link";
import React from "react";
import {UI_ACTION_CREATORS} from "../../redux/features/ui/ui-slice";

import {motion} from "framer-motion";
import {Link as ScrollLink} from "react-scroll";

const container = {
    hidden: {
        opacity: 0,
        x: '-10vh'
    },
    visible: {
        opacity: 1,
        x: 0,
        transition: {
            bounce: 1,
            staggerChildren: 0.4,
            when: "beforeChildren",
            stiffness: 150
        }
    }
}
const item = {
    hidden: {
        opacity: 0,
        x: '-10vh'
    },
    visible: {
        opacity: 1,
        x: 0,
        transition: {
            bounce: 1,
            stiffness: 150
        }
    },
};
const Drawer = () => {

    const dispatch = useDispatch();

    return (
        <Container
            sx={{
                width: '90vw',
                height: '100vh',
                paddingTop: 2,
                paddingBottom: 2,
                backgroundColor: 'background.paper'
            }}>
            <Button
                onClick={() => dispatch(UI_ACTION_CREATORS.toggleDrawer(false))}
                variant="text"
                size="large"
                color="secondary"
                sx={{
                    mb: 4,
                    backgroundColor: "light.secondary",
                    borderTopLeftRadius: 0,
                    borderBottomLeftRadius: 32,
                    borderBottomRightRadius: 32,
                    borderTopRightRadius: 32,
                    textTransform: "capitalize"
                }}
                endIcon={<Close color="secondary"/>}>
                Close
            </Button>

            <Typography
                whileInView={{y: 0, opacity: 1}}
                initial={{y: '-10vh', opacity: 0}}
                transition={{
                    delay: 0.5,
                    duration: 1,
                    type: 'spring',
                    bounce: 0.5,
                    stiffness: 150
                }}
                viewport={{once: true}}
                whileHover={{
                    scale: 1.3,
                    textShadow: "2px 5px 5px #f9a34f",
                    rotateZ: 10,
                    transition: {
                        delay: 0.3,
                        duration: 0.5
                    }
                }}
                component={motion.h4}
                sx={{
                    fontWeight: 'bolder',
                    color: 'secondary.main'
                }}
                mb={2}
                variant="h4">Super Craft</Typography>
            <Typography
                whileInView={{y: 0, opacity: 1}}
                initial={{y: '-10vh', opacity: 0}}
                transition={{
                    delay: 0.5,
                    duration: 1,
                    type: 'spring',
                    bounce: 0.5,
                    stiffness: 150
                }}
                viewport={{once: true}}
                whileHover={{
                    scale: 1.3,
                    textShadow: "2px 5px 5px #f9a34f",
                    rotateZ: 10,
                    transition: {
                        delay: 0.3,
                        duration: 0.5
                    }
                }}
                component={motion.p}
                sx={{
                    color: 'secondary.main',
                    fontWeight: 500
                }}
                mb={4}
                variant="body1">
                The home of blacksmith
            </Typography>

            <Stack
                component={motion.div}
                variants={container}
                animate="visible"
                initial="hidden"
                direction="column"
                spacing={2}>
                <Box component={motion.div} variants={item}>
                    <ScrollLink offset={-70} spy={true} smooth={true} duration={2000} delay={500} to="home">
                        <SideBarLinkItem
                            icon={
                                <HomeOutlined
                                    sx={{
                                        color: "secondary.main",
                                        borderTopLeftRadius: 0,
                                        borderBottomLeftRadius: 32,
                                        borderBottomRightRadius: 32,
                                        borderTopRightRadius: 32,
                                        padding: 0.4,
                                        fontSize: 32,
                                        backgroundColor: 'light.secondary'
                                    }}/>}
                            label="Home"
                        />
                    </ScrollLink>
                </Box>
                <Box component={motion.div} variants={item}>
                    <ScrollLink offset={-70} spy={true} smooth={true} duration={2000} delay={500} to="services">
                        <SideBarLinkItem
                            icon={
                                <DesignServicesOutlined
                                    sx={{
                                        color: "secondary.main",
                                        borderTopLeftRadius: 0,
                                        borderBottomLeftRadius: 32,
                                        borderBottomRightRadius: 32,
                                        borderTopRightRadius: 32,
                                        padding: 0.4,
                                        fontSize: 32,
                                        backgroundColor: 'light.secondary'
                                    }}/>}
                            label="Services"
                        />
                    </ScrollLink>
                </Box>
                <Box component={motion.div} variants={item}>
                    <ScrollLink offset={-70} spy={true} smooth={true} duration={2000} delay={500} to="about">
                        <SideBarLinkItem
                            icon={
                                <InfoOutlined
                                    sx={{
                                        color: "secondary.main",
                                        borderTopLeftRadius: 0,
                                        borderBottomLeftRadius: 32,
                                        borderBottomRightRadius: 32,
                                        borderTopRightRadius: 32,
                                        padding: 0.4,
                                        fontSize: 32,
                                        backgroundColor: 'light.secondary'
                                    }}/>}
                            label="About"
                        />
                    </ScrollLink>
                </Box>
                <Box component={motion.div} variants={item}>
                    <ScrollLink offset={-70} spy={true} smooth={true} duration={2000} delay={500} to="contact">
                        <SideBarLinkItem
                            icon={
                                <ContactPageOutlined
                                    sx={{
                                        color: "secondary.main",
                                        borderTopLeftRadius: 0,
                                        borderBottomLeftRadius: 32,
                                        borderBottomRightRadius: 32,
                                        borderTopRightRadius: 32,
                                        padding: 0.4,
                                        fontSize: 32,
                                        backgroundColor: 'light.secondary'
                                    }}/>}
                            label="Contact"
                        />
                    </ScrollLink>
                </Box>
            </Stack>
        </Container>
    )
}

export default Drawer;
