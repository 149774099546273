import {configureStore} from "@reduxjs/toolkit";
import uiReducer from "./../features/ui/ui-slice";
import messageReducer from "./../features/message/message-slice";

const store = configureStore({
    devTools: true,
    reducer: {
        ui: uiReducer,
        message: messageReducer
    }
});

export default store;
