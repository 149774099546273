import {Box} from "@mui/material";
import DesktopFooter from "./desktop-footer";
import MobileFooter from "./mobile-footer";
import Copyright from "../shared/copyright";

const TopBar = () => {
    return (
        <Box>
            <Box sx={{display: {xs: 'none', lg: 'block'}}}>
                <DesktopFooter/>
            </Box>
            <Box sx={{display: {xs: 'block', lg: 'none'}}}>
                <MobileFooter/>
            </Box>
            <Box sx={{backgroundColor: 'background.default'}}>
                <Copyright />
            </Box>
        </Box>
    )
}

export default TopBar
