import {Link} from "@mui/material";

const SocialLink = ({link, icon}) => {
    return (
        <Link href={link} target="_blank" underline="none">
            {icon}
        </Link>
    )
}

export default SocialLink;
