import {CardMedia} from "@mui/material";

const Client = ({client}) => {

    return (
        <CardMedia
            src={client.logo}
            component="img"
            sx={{
                height: 150,
                objectFit: 'contain',
                objectPosition: 'center',
                borderBottomLeftRadius: 0,
                borderBottomRightRadius: 32,
                borderTopLeftRadius: 32,
                borderTopRightRadius: 32,
                backgroundColor: "rgba(0, 0, 0, 0.2)"
            }}
        />
    )
}

export default Client;
